import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import ApiHelper from './Helpers/api'


const pluginSettings = window.pluginSettings

ApiHelper.init(pluginSettings.apiURL)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <App apiHelper={ApiHelper}/>
  </React.Fragment>
)