// eslint-disable-next-line 
export default {
  _url: '',

  pluginSettings: window.pluginSettings,

  setToken(token) {
    this._token = token
  },

  setApiToken(token) {
    this._apitoken = token
  },

  getToken() {
    return this._token
  },

  getApiToken() {
    return this._apitoken
  },

  getHeadersWithDefaults(apiKey) {
    let newHeader = {
      'X-CSRF-TOKEN': this._token,
      "api-token-key": apiKey,
      'Content-Type': 'application/json'
    }

    if (apiKey !== '') {
      newHeader.Authorization = `Bearer ${apiKey}`
    }

    return newHeader
  },

  init(apiUrl) {
    this._url = apiUrl
    return this
  },

  startRender(timestamp) {
    const request = new Request(`${this._url}/test/starttestrender/${timestamp}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        "api-token": this.pluginSettings.token
      }
    })
    return fetch(request)
  },

  login(password) {
    const request = new Request(`${this._url}/test/login/${password}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        "api-token": this.pluginSettings.token
      }
    })
    return fetch(request)
  }
}
