import { useState } from 'react'
import LoadingOverlay from "react-loading-overlay"
import useWebSocket from "react-use-websocket"
import { useStopwatch } from 'react-timer-hook'

import './App.css';

LoadingOverlay.propTypes = undefined
function App(props) {

  const [loading, setLoading] = useState(false)
  const [firstRun, setFirstRun] = useState(true)
  const [password, setPassword] = useState("")
  const [loginCookie, setLoginCookie] = useState(false)
  const [image, setImage] = useState()
  const [timestamp, setTimestamp] = useState()


  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false })

  const socketUrl = window.pluginSettings.websocket

  // const fingerprint = 'your-self-signed-certificate-fingerprint';
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    // headers: {
    //   'Sec-WebSocket-Protocol': 'your-protocol',
    //   'Sec-WebSocket-Key': 'your-websocket-key',
    //   'Sec-WebSocket-Version': '13',
    // },
    // certFingerprint: fingerprint,
    onOpen: () => {
      console.log("connection opened");
    },
    share: true,
    onMessage: (message) => {
      let data = JSON.parse(message.data)
      if (data.message == `done${timestamp}`) {
        pause()
        setLoading(false)
        setImage(data.image)
      }
    },
    onClose: (res) => {
      console.log(res)
      console.log("connection closed");
    },

    shouldReconnect: (closeEvent) => true,
  })


  const passwordChange = (e) => {
    setPassword(e.target.value)
  }

  const okButtonClick = async () => {
    let login = await props.apiHelper.login(password)
    console.log({ login })
    if (login.status == 200) {
      setLoginCookie(true)
    }
  }

  const buttonClick = () => {
    if (firstRun) {
      setFirstRun(false)
    }
    reset()
    start()
    setLoading(true)
    setImage()
    let ts = new Date().valueOf()
    setTimestamp(ts)
    console.log({ ts })
    props.apiHelper.startRender(ts)
  }

  return (
    <LoadingOverlay
      fadeSpeed={100}
      spinner
      active={loading}
      text="Please wait for render to complete...."
      styles={{
        wrapper: (base) => ({
          ...base,
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "auto",
          zIndex: 1500
        }),
        overlay: (base) => ({
          ...base,
          zIndex: 1500
        })

      }}
    >

      {!loginCookie ?
        <div className="passwordinput">
          <h3>Password</h3>
          <input type="password" onChange={passwordChange.bind(this)} value={password} autoComplete="new-password"></input>
          <button onClick={okButtonClick.bind(this)}>OK</button>
        </div>

        :
        <div className="App">
          <button disabled={isRunning} onClick={buttonClick.bind(this)}>START RENDER</button>
          {!firstRun ?
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontSize: '100px' }}>
                <span>{minutes.toString().length === 1 ? "0" : ""}{minutes}</span>:<span>{seconds.toString().length === 1 ? "0" : ""}{seconds}</span>
              </div>
            </div>
            : null}
          {image ?
            <div style={{ textAlign: 'center' }}>
              <div><a href={`https://scoutandswan-render-test.s3.eu-west-2.amazonaws.com/output/${timestamp}.png`} target="_blank">https://scoutandswan-render-test.s3.eu-west-2.amazonaws.com/output/{timestamp}.png</a></div>
              <img src={`data:image/png;base64,${image}`} />
            </div>
            : null}
        </div>
      }
    </LoadingOverlay >
  );
}

export default App;
